var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tableBox",attrs:{"id":"Empresas-tb7"}},[_c('div',{staticClass:"tableRow"},[_vm._l((_vm.cabcTabela7),function(item,j){return _c('div',{key:item,staticClass:"tab",class:{
        'sb-2': j == 0 || j == 6 || j == 8,
        'sb-1': j != 0 && j != 6 && j != 8,
        'sb-right': j != 8,
      }},[_c('div',{staticClass:"cabecTab",class:{
          'sb-2 paddMaior': j == 0 || j == 6 || j == 8,
          'sb-1 paddMenor': j != 0 && j != 6 && j != 8,
          red: j == 2 || j == 3 || j == 4,
          blue: j != 2 && j != 3 && j != 4,
        },attrs:{"id":'B7Tabelas4-' + j}},[_c('span',[_vm._v(" "+_vm._s(item)+" ")])]),_vm._l((_vm.valores1Col_tabela7),function(res,k){return _c('div',{key:res,staticClass:"resultTab",class:{
          'sb-2': j == 0 || j == 6 || j == 8,
          'sb-1': j != 0 && j != 6 && j != 8,
          'sb-right': j != 8,
          titulo: j == 0,
        },attrs:{"id":'B7Tabelas4-linhas-' + k}},[(j == 0)?_c('span',[_vm._v(" "+_vm._s(res)+" ")]):(j == 1)?[_c('Check',{attrs:{"valor":_vm.resultados[_vm.getResultadoFlagPossuiEncerrradas(k)]}})]:(j == 2)?[_c('Check',{attrs:{"valor":_vm.resultados[_vm.getResultadoFlagFalencia(k)]}})]:(j == 3)?[_c('Check',{attrs:{"valor":_vm.resultados[_vm.getResultadoFlagRecJud(k)]}})]:(j == 4)?[_c('Check',{attrs:{"valor":_vm.resultados[_vm.getResultadoFlagSitEsp(k)]}})]:(j == 5)?[_vm._v(" "+_vm._s(_vm.resultados[_vm.getResultadoQtdEncerradas(k)])+" ")]:(j == 6)?[_vm._v(" "+_vm._s(_vm.resultados[_vm.getResultadoBaixaEncerradas(k)])+" ")]:(j == 7)?[_vm._v(" "+_vm._s(_vm.resultados[_vm.getResultadoRecenciaEncerradas(k)])+" ")]:(j == 8)?[_vm._v(" "+_vm._s(_vm.formatarNumeroComPonto( _vm.resultados[_vm.getResultadoCapSocialEncerradas(k)] ))+" ")]:_vm._e()],2)})],2)}),_vm._l((_vm.tooltipsBloco7),function(tt,i){return _c('div',{key:i},[(_vm.exibirTootipsConsultas)?_c('b-tooltip',{staticClass:"tooltipTexts",attrs:{"target":tt.target,"triggers":"hover","placement":"bottom","variant":"dark","boundary":"window"}},[_c('div',{domProps:{"innerHTML":_vm._s(tt.texto)}})]):_vm._e()],1)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }