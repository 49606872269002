<template>
  <div class="tableBox">
    <div class="tableRow">
      <div
        class="tab"
        v-for="(item, j) in cabcTabela3"
        :key="item"
        :class="{
          'sb-2': j == 0 || j == 3,
          'sb-1': j != 0 && j != 3,
          'sb-right': j != 3,
        }"
      >
        <div
          class="cabecTab azul"
          :id="'B7Tabelas2-tb1-' + j"
          :style="j == 3 ? 'width: 100%' : 'width: 115%'"
          :class="{
            'sb-2': j == 0 || j == 3,
            'sb-1': j != 0 && j != 3,
          }"
        >
          <span>
            {{ item }}
          </span>
        </div>

        <div
          class="resultTab"
          v-for="(res, k) in valores1Col_tabela3"
          :key="res"
          :id="'B7Tabelas2-tb1-linhas-' + k"
          :class="{
            'sb-2': j == 0 || j == 3,
            'sb-1': j != 0 && j != 3,
            'sb-right': j != 3,
            titulo: j == 0,
          }"
        >
          <template v-if="j == 0">
            {{ res }}
          </template>
          <template v-else-if="j == 1">
            <Check :valor="resultados[getResultadoFlagAtiva(k)]" />
          </template>
          <template v-else-if="j == 2">
            {{ resultados[getResultadoTotalAtiva(k)] }}
          </template>
          <template v-else-if="j == 3">
            {{ formatarNumeroComPonto(resultados[getResultadoValorTotal(k)]) }}
          </template>
        </div>
      </div>
    </div>
    <div class="tableRow">
      <div
        class="tab"
        v-for="(item, j) in cabcTabela4"
        :key="item"
        :class="{
          'sb-2': j == 0 || j == 3,
          'sb-1': j != 0 && j != 3,
          'sb-right': j != 3,
        }"
      >
        <div
          class="cabecTab verde"
          :style="j == 3 ? 'width: 100%' : 'width: 115%'"
          :id="'B7Tabelas2-tb2-' + j"
          :class="{
            'sb-2': j == 0 || j == 3,
            'sb-1': j != 0 && j != 3,
          }"
        >
          <span>
            {{ item }}
          </span>
        </div>

        <div
          class="resultTab"
          v-for="(res, k) in valores1Col_tabela4"
          :key="res"
          :id="'B7Tabelas2-tb2-linhas-' + k"
          :class="{
            'sb-2': j == 0 || j == 3,
            'sb-1': j != 0 || j != 3,
            'sb-right': j != 3,
            titulo: j == 0,
          }"
        >
          <span v-if="j == 0">
            {{ res }}
          </span>
          <template v-else-if="j == 1">
            <Check :valor="resultados[getResultadoFlagOptante(k)]" />
          </template>
          <template v-else-if="j == 2">
            {{ resultados[getResultadoTotalOptante(k)] }}
          </template>
          <template v-else-if="j == 3">
            {{
              formatarNumeroComPonto(
                resultados[getResultadoValorTotalOptante(k)]
              )
            }}
          </template>
        </div>
      </div>
      <div v-for="(tt, i) in tooltipsBloco7" :key="i">
        <b-tooltip
        v-if="exibirTootipsConsultas"
          :target="tt.target"
          triggers="hover"
          placement="bottom"
          variant="dark"
          class="tooltipTexts"
          boundary="window"
        >
          <div v-html="tt.texto"></div>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import Check from "@/components/SuperBox/Check.vue";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";

export default {
  name: "B7Tabelas2",

  components: { Check },

  props: {
    resultados: Object,
    exibirTootipsConsultas: Boolean,
  },

  data() {
    return {
      cabcTabela3: [
        "Visão por Porte de Empresa",
        "Flag de Ativa",
        "Total de Ativas",
        "Montante total de Capital Social (R$)",
      ],

      cabcTabela4: [
        "Visão por Optante Simples",
        "Flag de Ativa",
        "Total de Ativas",
        "Montante total de Capital Social (R$)",
      ],
      valores1Col_tabela3: [
        "Microempreendedor Individual (MEI)",
        "Microempresa (ME)",
        "Pequeno Porte (EPP)",
        "Médio ou Grande Porte",
        "Sem Enquadramento ou Outro",
      ],
      valores1Col_tabela4: ["Optante = 'Sim'", "Optante = 'Não'"],
    };
  },

  methods: {
    formatarNumeroComPonto(numero) {
      if (numero != "-") {
        const numeroString = numero.toString().replace(".", ",");
        const partes = numeroString.split(",");
        partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return partes.join(",");
      } else {
        return numero;
      }
    },

    getResultadoFlagAtiva(i) {
      return [
        "bk_103_004_flag_ativa_porte_mei",
        "bk_103_004_flag_ativa_porte_me",
        "bk_103_004_flag_ativa_porte_epp",
        "bk_103_004_flag_ativa_porte_mgp",
        "bk_103_004_flag_ativa_porte_outros",
      ][i];
    },
    getResultadoTotalAtiva(i) {
      return [
        "bk_103_004_qtd_total_ativas_porte_mei",
        "bk_103_004_qtd_total_ativas_porte_me",
        "bk_103_004_qtd_total_ativas_porte_epp",
        "bk_103_004_qtd_total_ativas_porte_mgp",
        "bk_103_004_qtd_total_ativas_porte_outros",
      ][i];
    },
    getResultadoValorTotal(i) {
      return [
        "bk_103_004_vlr_total_capital_social_porte_mei",
        "bk_103_004_vlr_total_capital_social_porte_me",
        "bk_103_004_vlr_total_capital_social_porte_epp",
        "bk_103_004_vlr_total_capital_social_porte_mgp",
        "bk_103_004_vlr_total_capital_social_porte_outros",
      ][i];
    },

    getResultadoFlagOptante(i) {
      return [
        "bk_103_004_flag_ativa_opcao_simples_a_sim",
        "bk_103_004_flag_ativa_opcao_simples_nao",
      ][i];
    },

    getResultadoTotalOptante(i) {
      return [
        "bk_103_004_qtd_total_opcao_simples_sim",
        "bk_103_004_qtd_total_opcao_simples_nao",
      ][i];
    },

    getResultadoValorTotalOptante(i) {
      return [
        "bk_103_004_vlr_total_capital_social_opcao_simples_sim",
        "bk_103_004_vlr_total_capital_social_opcao_simples_nao",
      ][i];
    },
  },
  computed: {
    tooltipsBloco7() {
      return listaTooltips.bloco07_tabelas2;
    },
  },
};
</script>

<style lang="scss" scoped>
.tableBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  cursor: default;
  gap: 5px;
}
.tableRow {
  width: 50%;
  display: flex;
  margin: 0 0px 10px 0px;
  cursor: default;
}

.cabecTab {
  height: 45px;
  padding: 2px 30px 2px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  color: white;
  margin: 0 -5px 0 0;
  line-height: 14px;
  display: flex;
  align-items: center;

  &.azul {
    background-color: #2f75b5;
  }
  &.azul-escuro {
    background-color: #44546a;
  }

  &.verde {
    background-color: #18817e;
  }
}
.resultTab {
  height: 40px;
  margin: 5px 5px 0 0;
  border: solid 1px #0070c0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  &.titulo {
    justify-content: flex-start;
    padding-left: 5px;
  }
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
