var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tableBox",attrs:{"id":"Empresas-tb1"}},[_c('div',{staticClass:"tableRow",staticStyle:{"margin-bottom":"20px"}},_vm._l((_vm.cabcTabela1),function(item,i){return _c('div',{key:item,staticClass:"tab",class:{
        'sb-2': i == 9 || i == 8,
        'sb-1':
          i == 0 ||
          i == 1 ||
          i == 2 ||
          i == 3 ||
          i == 4 ||
          i == 5 ||
          i == 6 ||
          i == 7,
        'sb-right': i != 9,
      },attrs:{"id":'B7Tabelas1-' + i}},[_c('div',{staticClass:"cabecTab -azul",class:{
          'sb-2 padding': i == 9 || i == 8,
          'sb-1':
            i == 0 ||
            i == 1 ||
            i == 2 ||
            i == 3 ||
            i == 4 ||
            i == 5 ||
            i == 6 ||
            i == 7,
          maior: i == 2,
        }},[_c('span',{class:{
            menor: i == 4,
          }},[_c('div',{domProps:{"innerHTML":_vm._s(item)}})])]),_c('div',{staticClass:"resultTab",class:{
          'sb-2': i == 9 || i == 8,
          'sb-1':
            i == 0 ||
            i == 1 ||
            i == 2 ||
            i == 3 ||
            i == 4 ||
            i == 5 ||
            i == 6 ||
            i == 7,
        }},[(i == 0)?[_c('Check',{attrs:{"valor":_vm.resultados[_vm.getResultado(i)]}})]:(i != 8 && i != 7)?[_vm._v(" "+_vm._s(_vm.resultados[_vm.getResultado(i)])+" ")]:(i == 7)?[_vm._v(" "+_vm._s(_vm.resultados[_vm.getResultado(i)])+" ")]:(i == 8)?[_vm._v(" "+_vm._s(_vm.formatarNumeroComPonto(_vm.resultados[_vm.getResultado(i)]))+" ")]:_vm._e()],2)])}),0),_c('div',{staticClass:"tableRow"},_vm._l((_vm.cabcTabela2),function(item,j){return _c('div',{key:item,staticClass:"tab2 text-left",class:{
        'sb-2': j == 0 || j == 9,
        'sb-1': j != 0 || j != 9,
        'sb-right': j != 9,
      }},[_c('div',{staticClass:"cabecTab -azul",attrs:{"id":'B7Tabelas1-tb2-' + j}},[_c('span',[_vm._v(" "+_vm._s(item)+" ")])]),_vm._l((_vm.valores1Col),function(res,k){return _c('div',{key:k,staticClass:"resultTab",class:{ titulo: j == 0, 'sb-top': k != 0 },attrs:{"id":'B7Tabelas1-tb2-linha-' + k}},[(j == 0)?[_vm._v(" "+_vm._s(res)+" ")]:(j == 1)?[_c('Check',{attrs:{"valor":_vm.resultados[_vm.getResultadoFlagAtiva(k)]}})]:(j == 2)?[_vm._v(" "+_vm._s(_vm.resultados[_vm.getResultadoTotalAtiva(k)])+" ")]:(j == 3)?[_vm._v(" "+_vm._s(_vm.resultados[_vm.getResultadoTotalFiliais(k)])+" ")]:(j == 4)?[_vm._v(" "+_vm._s(_vm.resultados[_vm.getResultadoTotalSocios(k)])+" ")]:(j == 5)?[_vm._v(" "+_vm._s(_vm.resultados[_vm.getResultadoSociosPF(k)])+" ")]:(j == 6)?[_vm._v(" "+_vm._s(_vm.resultados[_vm.getResultadoSociosPJ(k)])+" ")]:(j == 7)?[_vm._v(" "+_vm._s(_vm.formatarData(_vm.resultados[_vm.getResultadoUltimoVinculo(k)]))+" ")]:(j == 8)?[_vm._v(" "+_vm._s(_vm.formatarData(_vm.resultados[_vm.getResultadoPrimeiroVinculo(k)]))+" ")]:(j == 9)?[_vm._v(" "+_vm._s(_vm.formatarNumeroComPonto(_vm.resultados[_vm.getResultadoValorTotal(k)]))+" ")]:_vm._e()],2)})],2)}),0),_vm._l((_vm.tooltipsBloco7),function(tt,i){return _c('div',{key:i},[(_vm.exibirTootipsConsultas)?_c('b-tooltip',{staticClass:"tooltipTexts",attrs:{"target":tt.target,"triggers":"hover","placement":"bottom","variant":"dark","boundary":"window"}},[_c('div',{domProps:{"innerHTML":_vm._s(tt.texto)}})]):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }