<template>
  <div class="tableBox" id="Empresas-tb7">
    <div class="tableRow">
      <div
        class="tab"
        v-for="(item, j) in cabcTabela7"
        :key="item"
        :class="{
          'sb-2': j == 0 || j == 6 || j == 8,
          'sb-1': j != 0 && j != 6 && j != 8,
          'sb-right': j != 8,
        }"
      >
        <div
          class="cabecTab"
          :id="'B7Tabelas4-' + j"
          :class="{
            'sb-2 paddMaior': j == 0 || j == 6 || j == 8,
            'sb-1 paddMenor': j != 0 && j != 6 && j != 8,
            red: j == 2 || j == 3 || j == 4,
            blue: j != 2 && j != 3 && j != 4,
          }"
        >
          <span>
            {{ item }}
          </span>
        </div>

        <div
          class="resultTab"
          v-for="(res, k) in valores1Col_tabela7"
          :key="res"
          :id="'B7Tabelas4-linhas-' + k"
          :class="{
            'sb-2': j == 0 || j == 6 || j == 8,
            'sb-1': j != 0 && j != 6 && j != 8,
            'sb-right': j != 8,
            titulo: j == 0,
          }"
        >
          <span v-if="j == 0">
            {{ res }}
          </span>
          <template v-else-if="j == 1">
            <Check :valor="resultados[getResultadoFlagPossuiEncerrradas(k)]" />
          </template>
          <template v-else-if="j == 2">
            <Check :valor="resultados[getResultadoFlagFalencia(k)]" />
          </template>
          <template v-else-if="j == 3">
            <Check :valor="resultados[getResultadoFlagRecJud(k)]" />
          </template>
          <template v-else-if="j == 4">
            <Check :valor="resultados[getResultadoFlagSitEsp(k)]" />
          </template>
          <template v-else-if="j == 5">
            {{ resultados[getResultadoQtdEncerradas(k)] }}
          </template>
          <template v-else-if="j == 6">
            {{ resultados[getResultadoBaixaEncerradas(k)] }}
          </template>
          <template v-else-if="j == 7">
            {{ resultados[getResultadoRecenciaEncerradas(k)] }}
          </template>
          <template v-else-if="j == 8">
            {{
              formatarNumeroComPonto(
                resultados[getResultadoCapSocialEncerradas(k)]
              )
            }}
          </template>
        </div>
      </div>
      <div v-for="(tt, i) in tooltipsBloco7" :key="i">
        <b-tooltip
        v-if="exibirTootipsConsultas"
          :target="tt.target"
          triggers="hover"
          placement="bottom"
          variant="dark"
          class="tooltipTexts"
          boundary="window"
        >
          <div v-html="tt.texto"></div>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import Check from "@/components/SuperBox/Check.vue";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";

export default {
  name: "B7Tabelas4",

  components: { Check },

  props: {
    resultados: Object,
    exibirTootipsConsultas: Boolean,
  },

  data() {
    return {
      cabcTabela7: [
        "Mapa de Empresas Encerradas Associadas",
        "Possui no Histórico",
        "Flag Falência",
        "Flag Rec. Jud.",
        "Flag Sit. Espec.",
        "Total no Histórico",
        "Tipo de Baixa",
        "Recência do Status",
        "Montante total de Capital Social (R$)",
      ],

      valores1Col_tabela7: [
        "Limitada (LTDA)",
        "Sociedade Anônima (SA)",
        "MEI",
        "EIRELI",
        "Empresa Individual (EI)",
        "Sociedade Simples (SS)",
        "Outro",
      ],
    };
  },

  methods: {
    getResultadoFlagPossuiEncerrradas(i) {
      return [
        "bk_103_004_flag_possui_encerradas_ltda",
        "bk_103_004_flag_possui_encerradas_sa",
        "bk_103_004_flag_possui_encerradas_mei",
        "bk_103_004_flag_possui_encerradas_eireli",
        "bk_103_004_flag_possui_encerradas_ei",
        "bk_103_004_flag_possui_encerradas_ss",
        "bk_103_004_flag_possui_encerradas_outros",
      ][i];
    },
    getResultadoFlagFalencia(i) {
      return [
        "bk_103_004_flag_encerradas_falencia_ltda",
        "bk_103_004_flag_falencia_sa",
        "bk_103_004_flag_falencia_mei",
        "bk_103_004_flag_falencia_eireli",
        "bk_103_004_flag_falencia_ei",
        "bk_103_004_flag_falencia_ss",
        "bk_103_004_flag_falencia_outros",
      ][i];
    },
    getResultadoFlagRecJud(i) {
      return [
        "bk_103_004_flag_encerradas_recuperacao_ltda",
        "bk_103_004_flag_encerradas_recuperacao_sa",
        "bk_103_004_flag_encerradas_recuperacao_mei",
        "bk_103_004_flag_encerradas_recuperacao_eireli",
        "bk_103_004_flag_encerradas_recuperacao_ei",
        "bk_103_004_flag_encerradas_recuperacao_ss",
        "bk_103_004_flag_encerradas_recuperacao_outros",
      ][i];
    },
    getResultadoFlagSitEsp(i) {
      return [
        "bk_103_004_flag_encerradas_situacao_especial_ltda",
        "bk_103_004_flag_encerradas_situacao_especial_sa",
        "bk_103_004_flag_encerradas_situacao_especial_mei",
        "bk_103_004_flag_encerradas_situacao_especial_eireli",
        "bk_103_004_flag_encerradas_situacao_especial_ei",
        "bk_103_004_flag_encerradas_situacao_especial_ss",
        "bk_103_004_flag_encerradas_situacao_especial_outros",
      ][i];
    },
    getResultadoQtdEncerradas(i) {
      return [
        "bk_103_004_qtd_total_encerradas_ltda",
        "bk_103_004_qtd_total_encerradas_sa",
        "bk_103_004_qtd_total_encerradas_mei",
        "bk_103_004_qtd_total_encerradas_eireli",
        "bk_103_004_qtd_total_encerradas_ei",
        "bk_103_004_qtd_total_encerradas_ss",
        "bk_103_004_qtd_total_encerradas_outros",
      ][i];
    },
    getResultadoBaixaEncerradas(i) {
      return [
        "bk_103_004_des_tipo_baixa_encerradas_ltda",
        "bk_103_004_des_tipo_baixa_encerradas_sa",
        "bk_103_004_des_tipo_baixa_encerradas_mei",
        "bk_103_004_des_tipo_baixa_encerradas_eireli",
        "bk_103_004_des_tipo_baixa_encerradas_ei",
        "bk_103_004_des_tipo_baixa_encerradas_ss",
        "bk_103_004_des_tipo_baixa_encerradas_outros",
      ][i];
    },
    getResultadoRecenciaEncerradas(i) {
      return [
        "bk_103_004_tmp_anos_recencia_status_encerradas_ltda",
        "bk_103_004_tmp_anos_recencia_status_encerradas_sa",
        "bk_103_004_tmp_anos_recencia_status_encerradas_mei",
        "bk_103_004_tmp_anos_recencia_status_encerradas_eireli",
        "bk_103_004_tmp_anos_recencia_status_encerradas_ei",
        "bk_103_004_tmp_anos_recencia_status_encerradas_ss",
        "bk_103_004_tmp_anos_recencia_status_encerradas_outros",
      ][i];
    },
    getResultadoCapSocialEncerradas(i) {
      return [
        "bk_103_004_vlr_total_capital_social_encerradas_ltda",
        "bk_103_004_vlr_total_capital_social_encerradas_sa",
        "bk_103_004_vlr_total_capital_social_encerradas_mei",
        "bk_103_004_vlr_total_capital_social_encerradas_eireli",
        "bk_103_004_vlr_total_capital_social_encerradas_ei",
        "bk_103_004_vlr_total_capital_social_encerradas_ss",
        "bk_103_004_vlr_total_capital_social_encerradas_outros",
      ][i];
    },

    formatarNumeroComPonto(numero) {
      if (numero != "-") {
        const numeroString = numero.toString().replace(".", ",");
        const partes = numeroString.split(",");
        partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return partes.join(",");
      } else {
        return numero;
      }
    },
  },
  computed: {
    tooltipsBloco7() {
      return listaTooltips.bloco07_tabelas4;
    },

  },

  watch: {

  },
};
</script>

<style lang="scss" scoped>
.tableBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  cursor: default;
}
.tableRow {
  display: flex;
  margin: 0 0px 20px 0px;
  cursor: default;
}

.cabecTab {
  height: 45px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  color: #0070c0;
  display: flex;
  flex-wrap: wrap;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &.red {
    color: white;
    background-color: #d65532;
    margin-right: 5px;
  }

  &.blue {
    background-color: #d9e1f2;
    border-radius: 5px;
    color: #363636;
  }

  &.paddMaior {
    padding: 2px 25px 2px 25px;
  }
  &.paddMenor {
    padding: 2px 5px 2px 5px;
  }
}
.resultTab {
  height: 40px;
  margin: 5px 0px 0 0;
  border: solid 1px #0070c0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  padding: 5px;
  word-break: break-word;
  line-height: 12px;
  // overflow: hidden;
  // text-overflow: ellipsis;

  &.titulo {
    justify-content: flex-start;
    padding-left: 5px;
  }
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
