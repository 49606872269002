<template>
  <div class="tableBox" id="Empresas-tb5">
    <div class="tableRow">
      <div
        class="tab"
        v-for="(item, j) in cabcTabela5"
        :key="item"
        :class="{
          'sb-2': j == 0 || j == 3,
          'sb-1': j != 0 && j != 3,
          'sb-right': j != 3,
        }"
      >
        <div
          class="cabecTab azul"
          :id="'B7Tabelas3-tb1-' + j"
          :style="j == 3 ? 'width: 100%' : 'width: 115%'"
          :class="{
            'sb-2 paddMaior': j == 0 || j == 3,
            'sb-1 paddMenor': j != 0 && j != 3,
          }"
        >
          <span>
            {{ item }}
          </span>
        </div>

        <div
          class="resultTab"
          v-for="(res, k) in valores1Col_tabela5"
          :key="res"
          :id="'B7Tabelas3-tb1-linhas-' + k"
          :class="{
            'sb-2': j == 0 || j == 3,
            'sb-1': j != 0 && j != 3,
            'sb-right': j != 3,
            titulo: j == 0,
          }"
        >
          <span v-if="j == 0">
            {{ res }}
          </span>
          <template v-else-if="j == 1">
            <Check :valor="resultados[getResultadoFlagPossuiCadastral(k)]" />
          </template>
          <template v-else-if="j == 2">
            {{ resultados[getResultadoQtdCadastral(k)] }}
          </template>
          <template v-else-if="j == 3">
            {{ resultados[getResultadoTempoCadastral(k)] }}
          </template>
        </div>
      </div>
    </div>
    <div class="tableRow">
      <div
        class="tab"
        v-for="(item, j) in cabcTabela6"
        :key="item"
        :class="{
          'sb-2': j == 0 || j == 3,
          'sb-1': j != 0 && j != 3,
          'sb-right': j != 3,
        }"
      >
        <div
          class="cabecTab verde"
          :style="j == 3 ? 'width: 100%' : 'width: 115%'"
          :id="'B7Tabelas3-tb2-' + j"
          :class="{
            'sb-2 paddMaior': j == 0 || j == 3,
            'sb-1 paddMenor': j != 0 && j != 3,
          }"
        >
          <span>
            {{ item }}
          </span>
        </div>

        <div
          class="resultTab"
          v-for="(res, k) in valores1Col_tabela6"
          :key="res"
          :id="'B7Tabelas3-tb2-linhas-' + k"
          :class="{
            'sb-2': j == 0 || j == 3,
            'sb-1': j != 0 && j != 3,
            'sb-right': j != 3,
            titulo: j == 0,
          }"
        >
          <template v-if="j == 0">
            {{ res }}
          </template>
          <template v-else-if="j == 1">
            <Check :valor="resultados[getResultadoFlagPossuiEspecial(k)]" />
          </template>
          <template v-else-if="j == 2">
            {{ resultados[getResultadoQtdEspecial(k)] }}
          </template>
          <template v-else-if="j == 3">
            {{ resultados[getResultadoTempoEspecial(k)] }}
          </template>
        </div>
      </div>
      <div v-for="(tt, i) in tooltipsBloco7" :key="i">
        <b-tooltip
        v-if="exibirTootipsConsultas"
          :target="tt.target"
          triggers="hover"
          placement="bottom"
          variant="dark"
          class="tooltipTexts"
          boundary="window"
        >
          <div v-html="tt.texto"></div>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import Check from "@/components/SuperBox/Check.vue";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";

export default {
  name: "B7Tabelas3",

  components: { Check },

  props: {
    resultados: Object,
    exibirTootipsConsultas: Boolean,
 },

  data() {
    return {
      cabcTabela5: [
        "Visão por Status Cadastral",
        "Possui no Histórico",
        "Total Histórico",
        "Recência do Status (em anos)",
      ],
      cabcTabela6: [
        "Visão por Situação Especial",
        "Possui no Histórico",
        "Total Histórico",
        "Recência do Status (em anos)",
      ],

      valores1Col_tabela5: [
        "Ativa",
        // "Ativa não regular",
        "Baixada",
        "Suspensa",
        "Inapta",
        "Nula",
        "Não Informada",
        "Outro",
      ],
      valores1Col_tabela6: [
        "Nula",
        "Espolio",
        "Intervenção",
        "Liquidação",
        "Falência",
        "Recuperação Judicial",
        "Outro",
      ],
    };
  },

  methods: {
    getResultadoFlagPossuiCadastral(i) {
      return [
        "bk_103_004_flag_status_cadastral_ativa",
        "bk_103_004_flag_status_cadastral_baixada",
        "bk_103_004_flag_status_cadastral_suspensa",
        "bk_103_004_flag_status_cadastral_inapta",
        "bk_103_004_flag_status_cadastral_nula",
        "bk_103_004_flag_status_cadastral_nao_informada",
        "bk_103_004_flag_status_cadastral_outros",
      ][i];
    },
    getResultadoQtdCadastral(i) {
      return [
        "bk_103_004_qtd_total_status_cadastral_ativa",
        "bk_103_004_qtd_total_status_cadastral_baixada",
        "bk_103_004_qtd_total_status_cadastral_suspensa",
        "bk_103_004_qtd_total_status_cadastral_inapta",
        "bk_103_004_qtd_total_status_cadastral_nula",
        "bk_103_004_qtd_total_status_cadastral_nao_informada",
        "bk_103_004_qtd_total_status_cadastral_outros",
      ][i];
    },
    getResultadoTempoCadastral(i) {
      return [
        "bk_103_004_tmp_anos_status_cadastral_ativa",
        "bk_103_004_tmp_anos_status_cadastral_baixada",
        "bk_103_004_tmp_anos_status_cadastral_suspensa",
        "bk_103_004_tmp_anos_status_cadastral_inapta",
        "bk_103_004_tmp_anos_status_cadastral_nula",
        "bk_103_004_tmp_anos_status_cadastral_nao_informada",
        "bk_103_004_tmp_anos_status_cadastral_outros",
      ][i];
    },
    getResultadoFlagPossuiEspecial(i) {
      return [
        "bk_103_004_flag_situacao_especial_nula",
        "bk_103_004_flag_situacao_especial_espolio",
        "bk_103_004_flag_situacao_especial_intervencao",
        "bk_103_004_flag_situacao_especial_liquidacao",
        "bk_103_004_flag_situacao_especial_falencia",
        "bk_103_004_flag_situacao_especial_recuperacao",
        "bk_103_004_flag_situacao_especial_outros",
      ][i];
    },
    getResultadoQtdEspecial(i) {
      return [
        "bk_103_004_qtd_total_situacao_especial_nula",
        "bk_103_004_qtd_total_situacao_especial_espolio",
        "bk_103_004_qtd_total_situacao_especial_intervencao",
        "bk_103_004_qtd_total_situacao_especial_liquidacao",
        "bk_103_004_qtd_total_situacao_especial_falencia",
        "bk_103_004_qtd_total_situacao_especial_recuperacao",
        "bk_103_004_qtd_total_situacao_especial_outros",
      ][i];
    },
    getResultadoTempoEspecial(i) {
      return [
        "bk_103_004_tmp_anos_recencia_situacao_especial_nula",
        "bk_103_004_tmp_anos_recencia_situacao_especial_espolio",
        "bk_103_004_tmp_anos_recencia_situacao_especial_intervencao",
        "bk_103_004_tmp_anos_recencia_situacao_especial_liquidacao",
        "bk_103_004_tmp_anos_recencia_situacao_especial_falencia",
        "bk_103_004_tmp_anos_recencia_situacao_especial_recuperacao",
        "bk_103_004_tmp_anos_recencia_situacao_especial_outros",
      ][i];
    },


  },

  computed: {
     tooltipsBloco7() {
      return listaTooltips.bloco07_tabelas3;
    },


  },

  watch: {

  },
};
</script>

<style lang="scss" scoped>
.tableBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  cursor: default;
  gap: 5px;
}
.tableRow {
  width: 50%;
  display: flex;
  margin: 0 0px 10px 0px;
  cursor: default;
}

.cabecTab {
  height: 45px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  color: #0070c0;
  line-height: 14px;
  display: flex;
  align-items: center;

  &.azul {
    background-color: #2f75b5;
    color: white;
  }
  &.azul-escuro {
    background-color: #44546a;
    color: white;
  }
  &.verde {
    background-color: #18817e;
    color: white;
  }
  &.paddMaior {
    padding: 2px 35px 2px 10px;
  }
  &.paddMenor {
    padding: 2px 20px 2px 10px;
  }
}
.resultTab {
  height: 40px;
  margin: 5px 5px 0 0;
  border: solid 1px #0070c0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  &.titulo {
    justify-content: flex-start;
    padding-left: 5px;
  }
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
