var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tableBox"},[_c('div',{staticClass:"tableRow"},_vm._l((_vm.cabcTabela3),function(item,j){return _c('div',{key:item,staticClass:"tab",class:{
        'sb-2': j == 0 || j == 3,
        'sb-1': j != 0 && j != 3,
        'sb-right': j != 3,
      }},[_c('div',{staticClass:"cabecTab azul",class:{
          'sb-2': j == 0 || j == 3,
          'sb-1': j != 0 && j != 3,
        },style:(j == 3 ? 'width: 100%' : 'width: 115%'),attrs:{"id":'B7Tabelas2-tb1-' + j}},[_c('span',[_vm._v(" "+_vm._s(item)+" ")])]),_vm._l((_vm.valores1Col_tabela3),function(res,k){return _c('div',{key:res,staticClass:"resultTab",class:{
          'sb-2': j == 0 || j == 3,
          'sb-1': j != 0 && j != 3,
          'sb-right': j != 3,
          titulo: j == 0,
        },attrs:{"id":'B7Tabelas2-tb1-linhas-' + k}},[(j == 0)?[_vm._v(" "+_vm._s(res)+" ")]:(j == 1)?[_c('Check',{attrs:{"valor":_vm.resultados[_vm.getResultadoFlagAtiva(k)]}})]:(j == 2)?[_vm._v(" "+_vm._s(_vm.resultados[_vm.getResultadoTotalAtiva(k)])+" ")]:(j == 3)?[_vm._v(" "+_vm._s(_vm.formatarNumeroComPonto(_vm.resultados[_vm.getResultadoValorTotal(k)]))+" ")]:_vm._e()],2)})],2)}),0),_c('div',{staticClass:"tableRow"},[_vm._l((_vm.cabcTabela4),function(item,j){return _c('div',{key:item,staticClass:"tab",class:{
        'sb-2': j == 0 || j == 3,
        'sb-1': j != 0 && j != 3,
        'sb-right': j != 3,
      }},[_c('div',{staticClass:"cabecTab verde",class:{
          'sb-2': j == 0 || j == 3,
          'sb-1': j != 0 && j != 3,
        },style:(j == 3 ? 'width: 100%' : 'width: 115%'),attrs:{"id":'B7Tabelas2-tb2-' + j}},[_c('span',[_vm._v(" "+_vm._s(item)+" ")])]),_vm._l((_vm.valores1Col_tabela4),function(res,k){return _c('div',{key:res,staticClass:"resultTab",class:{
          'sb-2': j == 0 || j == 3,
          'sb-1': j != 0 || j != 3,
          'sb-right': j != 3,
          titulo: j == 0,
        },attrs:{"id":'B7Tabelas2-tb2-linhas-' + k}},[(j == 0)?_c('span',[_vm._v(" "+_vm._s(res)+" ")]):(j == 1)?[_c('Check',{attrs:{"valor":_vm.resultados[_vm.getResultadoFlagOptante(k)]}})]:(j == 2)?[_vm._v(" "+_vm._s(_vm.resultados[_vm.getResultadoTotalOptante(k)])+" ")]:(j == 3)?[_vm._v(" "+_vm._s(_vm.formatarNumeroComPonto( _vm.resultados[_vm.getResultadoValorTotalOptante(k)] ))+" ")]:_vm._e()],2)})],2)}),_vm._l((_vm.tooltipsBloco7),function(tt,i){return _c('div',{key:i},[(_vm.exibirTootipsConsultas)?_c('b-tooltip',{staticClass:"tooltipTexts",attrs:{"target":tt.target,"triggers":"hover","placement":"bottom","variant":"dark","boundary":"window"}},[_c('div',{domProps:{"innerHTML":_vm._s(tt.texto)}})]):_vm._e()],1)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }