<template>
  <div class="tableBox" id="Empresas-tb1">
    <div class="tableRow" style="margin-bottom: 20px">
      <div
        class="tab"
        v-for="(item, i) in cabcTabela1"
        :class="{
          'sb-2': i == 9 || i == 8,
          'sb-1':
            i == 0 ||
            i == 1 ||
            i == 2 ||
            i == 3 ||
            i == 4 ||
            i == 5 ||
            i == 6 ||
            i == 7,
          'sb-right': i != 9,
        }"
        :id="'B7Tabelas1-' + i"
        :key="item"
      >
        <div
          class="cabecTab -azul"
          :class="{
            'sb-2 padding': i == 9 || i == 8,
            'sb-1':
              i == 0 ||
              i == 1 ||
              i == 2 ||
              i == 3 ||
              i == 4 ||
              i == 5 ||
              i == 6 ||
              i == 7,
            maior: i == 2,
          }"
        >
          <span
            :class="{
              menor: i == 4,
            }"
          >
            <div v-html="item"></div>
            <!-- {{ item }} -->
          </span>
        </div>

        <div
          class="resultTab"
          :class="{
            'sb-2': i == 9 || i == 8,
            'sb-1':
              i == 0 ||
              i == 1 ||
              i == 2 ||
              i == 3 ||
              i == 4 ||
              i == 5 ||
              i == 6 ||
              i == 7,
          }"
        >
          <template v-if="i == 0">
            <Check :valor="resultados[getResultado(i)]" />
          </template>
          <template v-else-if="i != 8 && i != 7">
            {{ resultados[getResultado(i)] }}
          </template>
          <template v-else-if="i == 7">
            {{ resultados[getResultado(i)] }}
          </template>
          <template v-else-if="i == 8">
            {{ formatarNumeroComPonto(resultados[getResultado(i)]) }}
          </template>
        </div>
      </div>
    </div>
    <div class="tableRow">
      <div
        class="tab2 text-left"
        v-for="(item, j) in cabcTabela2"
        :key="item"
        :class="{
          'sb-2': j == 0 || j == 9,
          'sb-1': j != 0 || j != 9,
          'sb-right': j != 9,
        }"
      >
        <div class="cabecTab -azul" :id="'B7Tabelas1-tb2-' + j">
          <span>
            {{ item }}
          </span>
        </div>

        <div
          class="resultTab"
          :class="{ titulo: j == 0, 'sb-top': k != 0 }"
          v-for="(res, k) in valores1Col"
          :key="k"
          :id="'B7Tabelas1-tb2-linha-' + k"
        >
          <template v-if="j == 0">
            {{ res }}
          </template>
          <template v-else-if="j == 1">
            <Check :valor="resultados[getResultadoFlagAtiva(k)]" />
          </template>
          <template v-else-if="j == 2">
            {{ resultados[getResultadoTotalAtiva(k)] }}
          </template>
          <template v-else-if="j == 3">
            {{ resultados[getResultadoTotalFiliais(k)] }}
          </template>
          <template v-else-if="j == 4">
            {{ resultados[getResultadoTotalSocios(k)] }}
          </template>
          <template v-else-if="j == 5">
            {{ resultados[getResultadoSociosPF(k)] }}
          </template>
          <template v-else-if="j == 6">
            {{ resultados[getResultadoSociosPJ(k)] }}
          </template>
          <template v-else-if="j == 7">
            {{ formatarData(resultados[getResultadoUltimoVinculo(k)]) }}
          </template>
          <template v-else-if="j == 8">
            {{ formatarData(resultados[getResultadoPrimeiroVinculo(k)]) }}
          </template>
          <template v-else-if="j == 9">
            {{ formatarNumeroComPonto(resultados[getResultadoValorTotal(k)]) }}
          </template>
        </div>
      </div>
    </div>
    <div v-for="(tt, i) in tooltipsBloco7" :key="i">
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="tt.target"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        <div v-html="tt.texto"></div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import Check from "@/components/SuperBox/Check.vue";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";

export default {
  name: "B7Tabelas1",

  components: { Check },

  props: {
    resultados: Object,
    exibirTootipsConsultas: Boolean,
  },

  data() {
    return {
      cabcTabela1: [
        "Flag de Empresa Ativa",
        "Total de Empresas Ativas",
        "Total de Matrizes e Filiais Ativas",
        "Total Vínculos PF",
        "Vínculos <br/>PF não<br/> Encontrados",
        "Total Vínculos PJ",
        "Total Vínculos Outros/Ext.",
        "Total de Vínc. Soc. Únicos",
        "Tempo máx. de Vínculo com Empresa (em anos)",
        "Montante total de Capital Social (R$)",
      ],
      cabcTabela2: [
        "Mapa de Empresas Ativas Associadas",
        "Flag de Ativa",
        "Total de Ativas",
        "Total de Filiais",
        "Total Geral de Sócios",
        "Total de Sócios PF",
        "Total de Sócios PJ",
        "Vínc. Sóc. + Recente",
        "Vínc. Sóc. + Antigo",
        "Montante total de Capital Social (R$)",
      ],
      valores1Col: [
        "Limitada (LTDA)",
        "Sociedade Anônima (SA)",
        "MEI",
        "EIRELI",
        "Empresa Individual (EI)",
        "Sociedade Simples (SS)",
        "Outro",
      ],
    };
  },

  methods: {
    formatarData(dataString) {
      if (dataString != null && dataString != "" && dataString != "-") {
        const data = new Date(dataString);
        const ano = data.getFullYear();
        const mes = (data.getMonth() + 1).toString().padStart(2, "0");
        const dia = data.getDate().toString().padStart(2, "0");

        return `${dia}/${mes}/${ano}`;
      } else {
        return dataString;
      }
    },

    formatarNumeroComPonto(numero) {
      if (numero != "-") {
        const numeroString = numero.toString().replace(".", ",");
        const partes = numeroString.split(",");
        partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return partes.join(",");
      } else {
        return numero;
      }
    },

    getResultado(i) {
      return [
        "flag_ind_sociedade_com_cnpj_ativo",
        "bk_103_004_qtd_total_cnpjs_ativos",
        "bk_103_004_qtd_total_filiais",
        "bk_103_004_qtd_total_socios_pf",
        "bk_103_004_qtd_total_socios_pf_nulos",
        "bk_103_004_qtd_total_socios_pj",
        "bk_103_004_qtd_total_socios_outrs",
        "bk_103_004_qtd_total_vinculos_unicos",
        "bk_103_004_tmp_max_vinculo_empresa_anos",
        "bk_103_004_vlr_total_capital_social",
      ][i];
    },

    getResultadoFlagAtiva(i) {
      return [
        "bk_103_004_flag_ativa_naruteza_juridica_ltda",
        "bk_103_004_flag_ativa_naruteza_juridica_sa",
        "bk_103_004_flag_ativa_naruteza_juridica_mei",
        "bk_103_004_flag_ativa_naruteza_juridica_eireli",
        "bk_103_004_flag_ativa_naruteza_juridica_ei",
        "bk_103_004_flag_ativa_naruteza_juridica_ss",
        "bk_103_004_flag_ativa_naruteza_juridica_outros",
      ][i];
    },
    getResultadoTotalAtiva(i) {
      return [
        "bk_103_004_qtd_total_cnpjs_ativos_naruteza_juridica_ltda",
        "bk_103_004_qtd_total_cnpjs_ativos_naruteza_juridica_sa",
        "bk_103_004_qtd_total_cnpjs_ativos_naruteza_juridica_mei",
        "bk_103_004_qtd_total_cnpjs_ativos_naruteza_juridica_eireli",
        "bk_103_004_qtd_total_cnpjs_ativos_naruteza_juridica_ei",
        "bk_103_004_qtd_total_cnpjs_ativos_naruteza_juridica_ss",
        "bk_103_004_qtd_total_cnpjs_ativos_naruteza_juridica_outros",
      ][i];
    },
    getResultadoTotalFiliais(i) {
      return [
        "bk_103_004_qtd_total_filiais_naruteza_juridica_ltda",
        "bk_103_004_qtd_total_filiais_naruteza_juridica_sa",
        "bk_103_004_qtd_total_filiais_naruteza_juridica_mei",
        "bk_103_004_qtd_total_filiais_naruteza_juridica_eireli",
        "bk_103_004_qtd_total_filiais_naruteza_juridica_ei",
        "bk_103_004_qtd_total_filiais_naruteza_juridica_ss",
        "bk_103_004_qtd_total_filiais_naruteza_juridica_outros",
      ][i];
    },
    getResultadoTotalSocios(i) {
      return [
        "bk_103_004_qtd_total_socios_naruteza_juridica_ltda",
        "bk_103_004_qtd_total_socios_naruteza_juridica_sa",
        "bk_103_004_qtd_total_socios_naruteza_juridica_mei",
        "bk_103_004_qtd_total_socios_naruteza_juridica_eireli",
        "bk_103_004_qtd_total_socios_naruteza_juridica_ei",
        "bk_103_004_qtd_total_socios_naruteza_juridica_ss",
        "bk_103_004_qtd_total_socios_naruteza_juridica_outros",
      ][i];
    },
    getResultadoSociosPF(i) {
      return [
        "bk_103_004_qtd_total_socios_pf_naruteza_juridica_ltda",
        "bk_103_004_qtd_total_socios_pf_naruteza_juridica_sa",
        "bk_103_004_qtd_total_socios_pf_naruteza_juridica_mei",
        "bk_103_004_qtd_total_socios_pf_naruteza_juridica_eireli",
        "bk_103_004_qtd_total_socios_pf_naruteza_juridica_ei",
        "bk_103_004_qtd_total_socios_pf_naruteza_juridica_ss",
        "bk_103_004_qtd_total_socios_pf_naruteza_juridica_outros",
      ][i];
    },
    getResultadoSociosPJ(i) {
      return [
        "bk_103_004_qtd_total_socios_pj_naruteza_juridica_ltda",
        "bk_103_004_qtd_total_socios_pj_naruteza_juridica_sa",
        "bk_103_004_qtd_total_socios_pj_naruteza_juridica_mei",
        "bk_103_004_qtd_total_socios_pj_naruteza_juridica_eireli",
        "bk_103_004_qtd_total_socios_pj_naruteza_juridica_ei",
        "bk_103_004_qtd_total_socios_pj_naruteza_juridica_ss",
        "bk_103_004_qtd_total_socios_pj_naruteza_juridica_outros",
      ][i];
    },
    getResultadoUltimoVinculo(i) {
      return [
        "bk_103_004_dt_ultimo_vinculo_naruteza_juridica_ltda",
        "bk_103_004_dt_ultimo_vinculo_naruteza_juridica_sa",
        "bk_103_004_dt_ultimo_vinculo_naruteza_juridica_mei",
        "bk_103_004_dt_ultimo_vinculo_naruteza_juridica_eireli",
        "bk_103_004_dt_ultimo_vinculo_naruteza_juridica_ei",
        "bk_103_004_dt_ultimo_vinculo_naruteza_juridica_ss",
        "bk_103_004_dt_ultimo_vinculo_naruteza_juridica_outros",
      ][i];
    },
    getResultadoPrimeiroVinculo(i) {
      return [
        "bk_103_004_dt_primeiro_vinculo_naruteza_juridica_ltda",
        "bk_103_004_dt_primeiro_vinculo_naruteza_juridica_sa",
        "bk_103_004_dt_primeiro_vinculo_naruteza_juridica_mei",
        "bk_103_004_dt_primeiro_vinculo_naruteza_juridica_eireli",
        "bk_103_004_dt_primeiro_vinculo_naruteza_juridica_ei",
        "bk_103_004_dt_primeiro_vinculo_naruteza_juridica_ss",
        "bk_103_004_dt_primeiro_vinculo_naruteza_juridica_outros",
      ][i];
    },
    getResultadoValorTotal(i) {
      return [
        "bk_103_004_vlr_total_capital_social_naruteza_juridica_ltda",
        "bk_103_004_vlr_total_capital_social_naruteza_juridica_sa",
        "bk_103_004_vlr_total_capital_social_naruteza_juridica_mei",
        "bk_103_004_vlr_total_capital_social_naruteza_juridica_eireli",
        "bk_103_004_vlr_total_capital_social_naruteza_juridica_ei",
        "bk_103_004_vlr_total_capital_social_naruteza_juridica_ss",
        "bk_103_004_vlr_total_capital_social_naruteza_juridica_outros",
      ][i];
    },
  },

  computed: {
    tooltipsBloco7() {
      return listaTooltips.bloco07_tabelas1;
    },
  },
};
</script>

<style lang="scss" scoped>
.tableBox {
  display: flex;
  flex-direction: column;
  cursor: default;
}
.tableRow {
  display: flex;
  margin: 0 0 10px 0px;
}

.cabecTab {
  height: 55px;
  width: 100%;
  margin: 0 0 5px 0px !important;
  padding: 2px 15px 1px 5px;
  font-size: 12px;
  font-weight: 400;
  color: #0070c0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 14px;
  text-align: center;

  &.-azul {
    background-color: #d9e1f2;
    border-radius: 5px;
    padding: 2px 9px;
    line-height: 14px;
    color: #363636;
  }

  &.maior {
    padding: 2px;
  }
}

.resultTab {
  height: 40px;
  border: solid 1px #0070c0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  &.titulo {
    justify-content: flex-start;
    padding-left: 5px;
  }
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
